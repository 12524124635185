export default [
  {
    path: "/",
    name: "login",
    meta: {
      guest: true,
    },
    component: () => import("../views/pages/account/login"),
  },
  {
    path: "/account/profile/",
    name: "profile",
    meta: {
      authRequired: true,
      // permission: "edit-admin-user",
    },
    component: () => import("../views/pages/account/profile"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      authRequired: true,
      permission: "admin-user",
    },
    component: () => import("../views/pages/admin-user/index"),
  },
  {
    path: "/admin/add-admin",
    name: "add-admin",
    meta: {
      authRequired: true,
      permission: "add-admin-user",
    },
    component: () => import("../views/pages/admin-user/addAdmin"),
  },
  {
    path: "/admin/edit-admin/:id",
    name: "edit-admin",
    meta: {
      authRequired: true,
      permission: "edit-admin-user",
    },
    component: () => import("../views/pages/admin-user/addAdmin"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/ask-expert",
    name: "ask-expert",
    meta: {
      authRequired: true,
      permission: "ask-expert",
    },
    component: () => import("../views/pages/ask-expert/index"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      authRequired: true,
      permission: "dashboard",
    },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/expert",
    name: "expert",
    meta: {
      authRequired: true,
      permission: "expert",
    },
    component: () => import("../views/pages/expert/index"),
  },
  {
    path: "/expert/add-expert",
    name: "add-expert",
    meta: {
      authRequired: true,
      permission: "add-expert",
    },
    component: () => import("../views/pages/expert/addExpert"),
  },
  {
    path: "/expert/edit-expert/:id",
    name: "edit-expert",
    meta: {
      authRequired: true,
      permission: "edit-expert",
    },
    component: () => import("../views/pages/expert/addExpert"),
  },
  {
    path: "/partner",
    name: "partner",
    meta: {
      authRequired: true,
      permission: "partner",
    },
    component: () => import("../views/pages/partner/index"),
  },
  {
    path: "/partner/add-partner",
    name: "add-partner",
    meta: {
      authRequired: true,
      permission: "add-partner",
    },
    component: () => import("../views/pages/partner/addPartner"),
  },
  {
    path: "/partner/edit-partner/:id",
    name: "edit-partner",
    meta: {
      authRequired: true,
      permission: "edit-partner",
    },
    component: () => import("../views/pages/partner/addPartner"),
  },
  {
    path: "/podcast",
    name: "podcast-management",
    meta: {
      authRequired: true,
      permission: "podcast",
    },
    component: () => import("../views/pages/podcast/index"),
  },
  {
    path: "/podcast/add-podcast",
    name: "add-podcast",
    meta: {
      authRequired: true,
      permission: "add-podcast",
    },
    component: () => import("../views/pages/podcast/addPodcast"),
  },
  {
    path: "/podcast/edit-podcast/:id",
    name: "edit-podcast",
    meta: {
      authRequired: true,
      permission: "edit-podcast",
    },
    component: () => import("../views/pages/podcast/addPodcast"),
  },
  {
    path: "/page",
    name: "page-management",
    meta: {
      authRequired: true,
      permission: "page",
    },
    component: () => import("../views/pages/page/index"),
  },
  {
    path: "/page/edit-page/:id",
    name: "edit-page",
    meta: {
      authRequired: true,
      permission: "edit-page",
    },
    component: () => import("../views/pages/page/addPage"),
  },
  {
    path: "/country",
    name: "country",
    meta: {
      authRequired: true,
      permission: "country",
    },
    component: () => import("../views/pages/country/index"),
  },
  {
    path: "/country/add-country",
    name: "add-country",
    meta: {
      authRequired: true,
      permission: "add-country",
    },
    component: () => import("../views/pages/country/addCountry"),
  },
  {
    path: "/country/edit-country/:id",
    name: "edit-country",
    meta: {
      authRequired: true,
      permission: "edit-country",
    },
    component: () => import("../views/pages/country/addCountry"),
  },
  {
    path: "/cityState",
    name: "city-state",
    meta: {
      authRequired: true,
      permission: "cityState",
    },
    component: () => import("../views/pages/cityState/index"),
  },
  {
    path: "/config",
    name: "config",
    meta: {
      authRequired: true,
      permission: "config",
    },
    component: () => import("../views/pages/config/index"),
  },
  {
    path: "/config/add-config",
    name: "add-config",
    meta: {
      authRequired: true,
      permission: "add-config",
    },
    component: () => import("../views/pages/config/addConfig"),
  },
  {
    path: "/config/edit-config/:id",
    name: "edit-config",
    meta: {
      authRequired: true,
      permission: "edit-config",
    },
    component: () => import("../views/pages/config/addConfig"),
  },
  {
    path: "/speciality",
    name: "speciality",
    meta: {
      authRequired: true,
      permission: "speciality",
    },
    component: () => import("../views/pages/speciality/index"),
  },
  {
    path: "/speciality/add-speciality",
    name: "add-speciality",
    meta: {
      authRequired: true,
      permission: "add-speciality",
    },
    component: () => import("../views/pages/speciality/addSpeciality"),
  },
  {
    path: "/speciality/edit-speciality/:id",
    name: "edit-speciality",
    meta: {
      authRequired: true,
      permission: "edit-speciality",
    },
    component: () => import("../views/pages/speciality/addSpeciality"),
  },
  {
    path: "/community",
    name: "community",
    meta: {
      authRequired: true,
      permission: "community",
    },
    component: () => import("../views/pages/community/index"),
  },
  {
    path: "/community/add-community",
    name: "add-community",
    meta: {
      authRequired: true,
      permission: "add-community",
    },
    component: () => import("../views/pages/community/addCommunity"),
  },
  {
    path: "/community/edit-community/:id",
    name: "edit-community",
    meta: {
      authRequired: true,
      permission: "edit-community",
    },
    component: () => import("../views/pages/community/addCommunity"),
  },
  {
    path: "/article",
    name: "article",
    meta: {
      authRequired: true,
      permission: "article",
    },
    component: () => import("../views/pages/article/index"),
  },
  {
    path: "/article/add-article",
    name: "add-article",
    meta: {
      authRequired: true,
      permission: "add-article",
    },
    component: () => import("../views/pages/article/addArticle"),
  },
  {
    path: "/article/edit-article/:id",
    name: "edit-article",
    meta: {
      authRequired: true,
      permission: "edit-article",
    },
    component: () => import("../views/pages/article/addArticle"),
  },
  {
    path: "/permission-denied",
    name: "permission-denied",
    meta: {
      guest: true,
    },
    component: () => import("../views/pages/utility/error-403"),
  },
  {
    path: "/role",
    name: "role",
    meta: {
      authRequired: true,
      permission: "role",
    },
    component: () => import("../views/pages/role/index"),
  },
  {
    path: "/role/add-role",
    name: "add-role",
    meta: {
      authRequired: true,
      permission: "add-role",
    },
    component: () => import("../views/pages/role/addRole"),
  },
  {
    path: "/role/edit-role/:id",
    name: "edit-role",
    meta: {
      authRequired: true,
      permission: "edit-role",
    },
    component: () => import("../views/pages/role/addRole"),
  },
  {
    path: "/case",
    name: "case",
    meta: {
      authRequired: true,
      permission: "case",
    },
    component: () => import("../views/pages/case/index"),
  },
  {
    path: "/case/add-case",
    name: "add-case",
    meta: {
      authRequired: true,
      permission: "add-case",
    },
    component: () => import("../views/pages/case/addCase"),
  },
  {
    path: "/case/edit-case/:id",
    name: "edit-case",
    meta: {
      authRequired: true,
      permission: "edit-case",
    },
    component: () => import("../views/pages/case/addCase"),
  },
  {
    path: "/case-question-mcq/:id",
    name: "case-question-mcq",
    meta: {
      authRequired: true,
      permission: "case-question-mcq",
    },
    component: () => import("../views/pages/case/caseQuestionMCQ"),
  },
  {
    path: "/case-question-comment/:id",
    name: "case-question-comment",
    meta: {
      authRequired: true,
      permission: "case-question-comment",
    },
    component: () => import("../views/pages/case/caseQuestionComment"),
  },
  {
    path: "/slider",
    name: "slider",
    meta: {
      authRequired: true,
      permission: "slider",
    },
    component: () => import("../views/pages/slider/index"),
  },
  {
    path: "/slider/add-slider",
    name: "add-slider",
    meta: {
      authRequired: true,
      permission: "add-slider",
    },
    component: () => import("../views/pages/slider/addSlider"),
  },
  {
    path: "/slider/edit-slider/:id",
    name: "edit-slider",
    meta: {
      authRequired: true,
      permission: "edit-slider",
    },
    component: () => import("../views/pages/slider/addSlider"),
  },
  {
    path: "/email-notification",
    name: "email-notification",
    meta: {
      authRequired: true,
      permission: "email-notification",
    },
    component: () => import("../views/pages/notification/email/index"),
  },
  {
    path: "/email-notification/add-email-notification",
    name: "add-email-notification",
    meta: {
      authRequired: true,
      permission: "add-email-notification",
    },
    component: () => import("../views/pages/notification/email/addEmail"),
  },
  {
    path: "/email-notification/edit-email-notification/:template_ref_no",
    name: "edit-email-notification",
    meta: {
      authRequired: true,
      permission: "edit-email-notification",
    },
    component: () => import("../views/pages/notification/email/addEmail"),
  },
  {
    path: "/sms-notification",
    name: "sms-notification",
    meta: {
      authRequired: true,
      permission: "sms-notification",
    },
    component: () => import("../views/pages/notification/sms/index"),
  },
  {
    path: "/sms-notification/add-sms-notification",
    name: "add-sms-notification",
    meta: {
      authRequired: true,
      permission: "add-sms-notification",
    },
    component: () => import("../views/pages/notification/sms/addSMS"),
  },
  {
    path: "/sms-notification/edit-sms-notification/:template_ref_no",
    name: "edit-sms-notification",
    meta: {
      authRequired: true,
      permission: "edit-sms-notification",
    },
    component: () => import("../views/pages/notification/sms/addSMS"),
  },
  {
    path: "/push-notification",
    name: "push-notification",
    meta: {
      authRequired: true,
      permission: "push-notification",
    },
    component: () => import("../views/pages/notification/push/index"),
  },
  {
    path: "/push-notification/add-push-notification",
    name: "add-push-notification",
    meta: {
      authRequired: true,
      permission: "add-push-notification",
    },
    component: () => import("../views/pages/notification/push/addPush"),
  },
  {
    path: "/push-notification/edit-push-notification/:template_ref_no",
    name: "edit-push-notification",
    meta: {
      authRequired: true,
      permission: "edit-push-notification",
    },
    component: () => import("../views/pages/notification/push/addPush"),
  },
  {
    path: "/page-notification",
    name: "page-notification",
    meta: {
      authRequired: true,
      permission: "page-notification",
    },
    component: () => import("../views/pages/notification/page/index"),
  },
  {
    path: "/page-notification/add-page-notification",
    name: "add-page-notification",
    meta: {
      authRequired: true,
      permission: "add-page-notification",
    },
    component: () => import("../views/pages/notification/page/addPage"),
  },
  {
    path: "/page-notification/edit-page-notification/:template_ref_no",
    name: "edit-page-notification",
    meta: {
      authRequired: true,
      permission: "edit-page-notification",
    },
    component: () => import("../views/pages/notification/page/addPage"),
  },
  {
    path: "/series",
    name: "series",
    meta: {
      authRequired: true,
      permission: "series",
    },
    component: () => import("../views/pages/series/index"),
  },
  {
    path: "/series/add-series",
    name: "add-series",
    meta: {
      authRequired: true,
      permission: "add-series",
    },
    component: () => import("../views/pages/series/addSeries"),
  },
  {
    path: "/series/edit-series/:id",
    name: "edit-series",
    meta: {
      authRequired: true,
      permission: "edit-series",
    },
    component: () => import("../views/pages/series/addSeries"),
  },
  {
    path: "/master-notification",
    name: "master-notification",
    meta: {
      authRequired: true,
      permission: "master-notification",
    },
    component: () => import("../views/pages/notification-master/index"),
  },
  {
    path: "/master-notification/add-master-notification",
    name: "add-master-notification",
    meta: {
      authRequired: true,
      permission: "add-master-notification",
    },
    component: () =>
      import("../views/pages/notification-master/addNotificationMaster"),
  },
  {
    path: "/master-notification/edit-master-notification/:notification_master_ref_no",
    name: "edit-master-notification",
    meta: {
      authRequired: true,
      permission: "edit-master-notification",
    },
    component: () =>
      import("../views/pages/notification-master/addNotificationMaster"),
  },
  {
    path: "/forum",
    name: "forum",
    meta: {
      authRequired: true,
      permission: "forum",
    },
    component: () => import("../views/pages/forum/index"),
  },
  {
    path: "/forum/add-forum",
    name: "add-forum",
    meta: {
      authRequired: true,
      permission: "add-forum",
    },
    component: () => import("../views/pages/forum/addForum"),
  },
  {
    path: "/forum/edit-forum/:id",
    name: "edit-forum",
    meta: {
      authRequired: true,
      permission: "edit-forum",
    },
    component: () => import("../views/pages/forum/addForum"),
  },
  {
    path: "/forum/view-request/:id",
    name: "view-request-forum",
    meta: {
      authRequired: true,
      // permission: "view-request-forum",
    },
    component: () => import("../views/pages/forum/viewRequest"),
  },
  {
    path: "/campaign/:id",
    name: "touchpoint",
    meta: {
      authRequired: true,
      permission: "communication",
    },
    component: () => import("../views/pages/campaign/touchpoint"),
  },
  {
    path: "/campaign/:id/add-touchpoint",
    name: "add-touchpoint",
    meta: {
      authRequired: true,
      permission: "add-communication",
    },
    component: () => import("../views/pages/campaign/addTouchpoint"),
  },
  {
    path: "/campaign/:id/edit-touchpoint",
    name: "edit-touchpoint",
    meta: {
      authRequired: true,
      permission: "edit-communication",
    },
    component: () => import("../views/pages/campaign/addTouchpoint"),
  },
  {
    path: "/campaign/:id/dashboard",
    name: "dashboard-touchpoint",
    meta: {
      authRequired: true,
      permission: "touchpoint",
    },
    component: () => import("../views/pages/campaign/touchpointDashboard.vue"),
  },
  // {
  //   path: '/communication/view-app-report/:id',
  //   name: 'view-app-report',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/engagement/app-notification/viewAppReport')
  // },
  // {
  //   path: '/communication/view-whatsapp-report/:id',
  //   name: 'view-whatsapp-report',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/engagement/app-notification/viewWhatsappReport')
  // },
  {
    path: "/dataFilters",
    name: "dataFilters",
    meta: {
      authRequired: true,
      permission: "dataFilters",
    },
    component: () => import("../views/pages/data-filters/index"),
  },
  {
    path: "/dataFilters/edit-dataFilters/:id",
    name: "edit-dataFilters",
    meta: {
      authRequired: true,
      permission: "edit-dataFilters",
    },
    component: () => import("../views/pages/data-filters/addDataFilters"),
  },
  {
    path: "/dataFilters/add-dataFilters",
    name: "add-dataFilters",
    meta: {
      authRequired: true,
      permission: "add-dataFilters",
    },
    component: () => import("../views/pages/data-filters/addDataFilters"),
  },
  {
    path: "/video",
    name: "video",
    meta: {
      authRequired: true,
      permission: "video",
    },
    component: () => import("../views/pages/video/index"),
  },
  {
    path: "/video/add-video",
    name: "add-video",
    meta: {
      authRequired: true,
      permission: "add-video",
    },
    component: () => import("../views/pages/video/addVideo"),
  },
  {
    path: "/video/edit-video/:id",
    name: "edit-video",
    meta: {
      authRequired: true,
      permission: "edit-video",
    },
    component: () => import("../views/pages/video/addVideo"),
  },
  {
    path: "/question-bank",
    name: "question-bank",
    meta: {
      authRequired: true,
      permission: "question-bank",
    },
    component: () => import("../views/pages/question-bank/index"),
  },
  {
    path: "/question-bank/add-question-bank",
    name: "add-question-bank",
    meta: {
      authRequired: true,
      permission: "add-question-bank",
    },
    component: () => import("../views/pages/question-bank/addQuestionBank"),
  },
  {
    path: "/question-bank/edit-question-bank/:id",
    name: "edit-question-bank",
    meta: {
      authRequired: true,
      permission: "edit-question-bank",
    },
    component: () => import("../views/pages/question-bank/addQuestionBank"),
  },
  { path: "*", component: () => import("../views/pages/utility/error-404") },
  {
    path: "/certificate",
    name: "certificate",
    meta: {
      authRequired: true,
      permission: "certificate",
    },
    component: () => import("../views/pages/certificate/index"),
  },
  {
    path: "/certificate/add-certificate",
    name: "add-certificate",
    meta: {
      authRequired: true,
      permission: "add-certificate",
    },
    component: () => import("../views/pages/certificate/addCertificate"),
  },
  {
    path: "/certificate/edit-certificate/:id",
    name: "edit-certificate",
    meta: {
      authRequired: true,
      permission: "edit-certificate",
    },
    component: () => import("../views/pages/certificate/addCertificate"),
  },
  {
    path: "/universal-member-upload",
    name: "Universal Member",
    meta: {
      authRequired: true,
      permission: "universal-member-upload",
    },
    component: () => import("../views/pages/universal-member/index"),
  },
  {
    path: "/newsletter",
    name: "newsletter",
    meta: {
      authRequired: true,
      permission: "newsletter",
    },
    component: () => import("../views/pages/newsletter/index"),
  },
  {
    path: "/newsletter/add-newsletter",
    name: "add-newsletter",
    meta: {
      authRequired: true,
      permission: "add-newsletter",
    },
    component: () => import("../views/pages/newsletter/addNewsletter"),
  },
  {
    path: "/newsletter/edit-newsletter/:id",
    name: "edit-newsletter",
    meta: {
      authRequired: true,
      permission: "edit-newsletter",
    },
    component: () => import("../views/pages/newsletter/addNewsletter"),
  },
  {
    path: "/cme",
    name: "cme",
    meta: {
      authRequired: true,
      permission: "cme",
    },
    component: () => import("../views/pages/cme/index"),
  },
  {
    path: "/cme/edit-cme/:id",
    name: "edit-cme",
    meta: {
      authRequired: true,
      permission: "edit-cme",
    },
    component: () => import("../views/pages/cme/addCME"),
  },
  {
    path: "/cme/add-cme",
    name: "add-cme",
    meta: {
      authRequired: true,
      permission: "add-cme",
    },
    component: () => import("../views/pages/cme/addCME"),
  },
  {
    path: "/protected-content",
    name: "protected-content",
    meta: {
      authRequired: true,
      permission: "protected-content",
    },
    component: () => import("../views/pages/protected-content/index"),
  },
  {
    path: "/protected-content/add-protected-content",
    name: "add-protected-content",
    meta: {
      authRequired: true,
      permission: "add-protected-content",
    },
    component: () =>
      import("../views/pages/protected-content/addProtectedContent"),
  },
  {
    path: "/live-event",
    name: "live-event",
    meta: {
      authRequired: true,
      permission: "live-event",
    },
    component: () => import("../views/pages/live-event/index"),
  },
  {
    path: "/live-event/add-live-event",
    name: "add-live-event",
    meta: {
      authRequired: true,
      permission: "add-live-event",
    },
    component: () => import("../views/pages/live-event/addLiveEvent"),
  },
  {
    path: "/live-event/edit-live-event/:id",
    name: "edit-live-event",
    meta: {
      authRequired: true,
      permission: "update-live-event",
    },
    component: () => import("../views/pages/live-event/addLiveEvent"),
  },
  {
    path: "/live-event/show-certificates-live-event/:id",
    name: "show-certificates",
    meta: {
      authRequired: true,
      // permission: "show-certificates",
    },
    component: () => import("../views/pages/live-event/showCertificate"),
  },
  {
    path: "/live-event/dashboard/:id",
    name: "live-event-dashboard",
    meta: {
      authRequired: true,
      permission: "live-event",
    },
    component: () => import("../views/pages/live-event/liveeventDashboard"),
  },
  // Protected live events routes
  {
    path: "/protected-live-event",
    name: "protected-live-event",
    meta: {
      authRequired: true,
      permission: "protected-live-event",
    },
    component: () => import("../views/pages/live-event/protected"),
  },
  {
    path: "/protected-live-event/members/:id",
    name: "protected-live-event-members",
    meta: {
      authRequired: true,
      permission: "protected-live-event",
    },
    component: () => import("../views/pages/live-event/members"),
  },
  {
    path: "/protected-live-event/members/status/:id",
    name: "protected-live-event-members-status",
    meta: {
      authRequired: true,
      permission: "protected-live-event",
    },
    component: () => import("../views/pages/live-event/members"),
  },
  {
    path: "/protected-live-event/members/upload",
    name: "protected-live-event-members-upload",
    meta: {
      authRequired: true,
      permission: "protected-live-event",
    },
    component: () => import("../views/pages/live-event/members"),
  },
  {
    path: "/promotion",
    name: "promotion",
    meta: {
      authRequired: true,
      permission: "show-certificates",
    },
    component: () => import("../views/pages/promotion/index"),
  },
  {
    path: "/promotion/add-promotion",
    name: "add-promotion",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/promotion/addPromotion"),
  },
  {
    path: "/promotion/edit-promotion/:id",
    name: "edit-promotion",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/promotion/addPromotion"),
  },
  {
    path: "/webview",
    name: "webview",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/webview/index"),
  },
  {
    path: "/webview/add-webview",
    name: "add-webview",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/webview/addWebview"),
  },
  {
    path: "/webview/edit-webview/:id",
    name: "edit-webview",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/webview/addWebview"),
  },
  // {
  //   path: '/registration',
  //   name: 'registration',
  //   meta: {
  //     authRequired: true,
  //     permission: 'registration'
  //   },
  //   component: () => import('../views/pages/registration/index')
  // },
  // {
  //   path: '/registration/add-registration',
  //   name: 'add-registration',
  //   meta: {
  //     authRequired: true,
  //     permission: 'add-registration'
  //   },
  //   component: () => import('../views/pages/registration/addRegistration')
  // },
  // {
  //   path: '/registration/edit-registration/:id',
  //   name: 'edit-registration',
  //   meta: {
  //     authRequired: true,
  //     permission: 'edit-registration'
  //   },
  //   component: () => import('../views/pages/registration/addRegistration')
  // }
  {
    path: "/immediate-action",
    name: "immediate-action",
    meta: {
      authRequired: true,
      permission: "immediate-action",
    },
    component: () => import("../views/pages/immediate-action/index"),
  },
  {
    path: "/immediate-action/add-immediate-action",
    name: "add-immediate-action",
    meta: {
      authRequired: true,
      permission: "add-immediate-action",
    },
    component: () =>
      import("../views/pages/immediate-action/addImmediateAction"),
  },
  {
    path: "/immediate-action/add-immediate-action/:id",
    name: "edit-immediate-action",
    meta: {
      authRequired: true,
      permission: "edit-immediate-action",
    },
    component: () =>
      import("../views/pages/immediate-action/addImmediateAction"),
  },
  {
    path: "/mci-verification",
    name: "mci-verification",
    meta: {
      authRequired: true,
      permission: "mci-verification",
    },
    component: () => import("../views/pages/mci-verification/index"),
  },
  {
    path: "/digiMr-management",
    name: "DigiMr",
    meta: {
      authRequired: true,
      permission: "DigiMr",
    },
    component: () => import("../views/pages/digiMR/index"),
  },
  {
    path: "/digiMr-management/add-digiMr",
    name: "add-digiMr",
    meta: {
      authRequired: true,
      permission: "add-digiMr",
    },
    component: () => import("../views/pages/digiMR/addDigiMR"),
  },
  {
    path: "/digiMr-management/edit-digiMr/:id",
    name: "edit-digiMr",
    meta: {
      authRequired: true,
      permission: "edit-digiMr",
    },
    component: () => import("../views/pages/digiMR/addDigiMR"),
  },
  {
    path: "/forum-tool",
    name: "forum-tool",
    meta: {
      authRequired: true,
      permission: "forum-tool",
    },
    component: () => import("../views/pages/forum-tool/index"),
  },
  {
    path: "/forum-tool/add",
    name: "add-forum-tool",
    meta: {
      authRequired: true,
      permission: "add-forum-tool",
    },
    component: () => import("../views/pages/forum-tool/addForumTool"),
  },
  {
    path: "/forum-tool/edit/:id",
    name: "edit-forum-tool",
    meta: {
      authRequired: true,
      permission: "edit-forum-tool",
    },
    component: () => import("../views/pages/forum-tool/addForumTool"),
  },
  {
    path: "/forum-vendor/add/:id/:forum_id",
    name: "add-vendor",
    meta: {
      authRequired: true,
      permission: "add-forum-vendor",
    },
    component: () =>
      import("../views/pages/forum-tool/settings/addVendorDetails"),
  },
  {
    path: "/forum-vendor/edit/:id",
    name: "edit-vendor",
    meta: {
      authRequired: true,
      permission: "edit-forum-vendor",
    },
    component: () =>
      import("../views/pages/forum-tool/settings/addVendorDetails"),
  },
  {
    path: "/forum-license/add/:id/:forum_id",
    name: "add-license",
    meta: {
      authRequired: true,
      permission: "add-forum-license",
    },
    component: () =>
      import("../views/pages/forum-tool/settings/addLicensesDetails"),
  },
  {
    path: "/forum-license/edit/:id/:forum_id",
    name: "edit-license",
    meta: {
      authRequired: true,
      permission: "edit-forum-license",
    },
    component: () =>
      import("../views/pages/forum-tool/settings/addLicensesDetails"),
  },
  {
    path: "/forum-settings/:id/:forum_id",
    name: "settings",
    meta: {
      authRequired: true,
      permission: "settings",
    },
    component: () =>
      import("../views/pages/forum-tool/settings/settingsForumTool"),
  },
  {
    path: "/campaign",
    name: "campaign",
    meta: {
      authRequired: true,
      permission: "campaign",
    },
    component: () => import("../views/pages/campaign/index"),
  },
  {
    path: "/campaign/add-campaign",
    name: "add-campaign",
    meta: {
      authRequired: true,
      permission: "add-campaign",
    },
    component: () => import("../views/pages/campaign/addCampaign"),
  },
  {
    path: "/campaign/edit-campaign/:id",
    name: "edit-campaign",
    meta: {
      authRequired: true,
      permission: "edit-campaign",
    },
    component: () => import("../views/pages/campaign/addCampaign"),
  },
  {
    path: "/campaign/view-campaign/:id",
    name: "view-single-campaign",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/campaign/viewCampaign"),
  },
  {
    path: "/touchpoint-list",
    name: "touchpoint-list",
    meta: {
      authRequired: true,
      permission: "communication",
    },
    component: () => import("../views/pages/campaign/touchpointList.vue"),
  },
  {
    path: "/main-analytics",
    name: "main-analytics",
    meta: {
      authRequired: true,
      permission: "main-analytics",
    },
    component: () => import("../views/pages/campaign/analyticsMenu"),
  },
  {
    path: "/campaign/:id/view-single-touchpoint",
    name: "view-single-touchpoint",
    meta: {
      authRequired: true,
      permission: "view-single-campaign",
    },
    component: () =>
      import("../views/pages/campaign/viewSingleTouchpointAnalytics"),
  },
  {
    path: "/stage",
    name: "stage",
    meta: {
      authRequired: true,
      permission: "stages",
    },
    component: () => import("../views/pages/stages/index"),
  },
  {
    path: "/stages/add-stage",
    name: "add-stage",
    meta: {
      authRequired: true,
      permission: "add-stage",
    },
    component: () => import("../views/pages/stages/addStage"),
  },
  {
    path: "/stages/edit-stage/:id",
    name: "edit-stage",
    meta: {
      authRequired: true,
      permission: "edit-stage",
    },
    component: () => import("../views/pages/stages/addStage"),
  },
  {
    path: "/metatag",
    name: "metatag",
    meta: {
      authRequired: true,
      permission: "MetaTag",
    },
    component: () => import("../views/pages/metatag/index"),
  },
  {
    path: "/metatag/add-metacontent",
    name: "add-metacontent",
    meta: {
      authRequired: true,
      permission: "add-metacontent",
    },
    component: () => import("../views/pages/metatag/addMetaTag"),
  },
  {
    path: "/metatag/edit-metacontent/:id",
    name: "edit-metacontent",
    meta: {
      authRequired: true,
      permission: "edit-metacontent",
    },
    component: () => import("../views/pages/metatag/addMetaTag"),
  },
  {
    path: "/member-tag-type",
    name: "member-tag-type",
    meta: {
      authRequired: true,
      permission: "member-tag-type",
    },
    component: () => import("../views/pages/member-tag-type/index"),
  },
  {
    path: "/member-tag-type/add-member-tag-type",
    name: "add-member-tag-type",
    meta: {
      authRequired: true,
      permission: "add-member-tag-type",
    },
    component: () => import("../views/pages/member-tag-type/addMemberTagType"),
  },
  {
    path: "/member-tag-type/edit-member-tag-type/:id",
    name: "edit-member-tag-type",
    meta: {
      authRequired: true,
      permission: "edit-member-tag-type",
    },
    component: () => import("../views/pages/member-tag-type/addMemberTagType"),
  },
  {
    path: "/member-list",
    name: "member-list",
    meta: {
      authRequired: true,
      permission: "member-list",
    },
    component: () => import("../views/pages/member-list/index"),
  },
  {
    path: "/member-list/social-links/:id",
    name: "member-social-links",
    meta: {
      authRequired: true,
      permission: "member-list",
    },
    component: () => import("../views/pages/member-list/member_social_links"),
  },
  {
    path: "/gpdata",
    name: "gpdata",
    meta: {
      authRequired: true,
      permission: "gp_data",
    },
    component: () => import("../views/pages/gpdata/index"),
  },
  {
    path: "/gpdata/edit-gpdata/:id",
    name: "edit-gpdata",
    meta: {
      authRequired: true,
      permission: "gp_data",
    },
    component: () => import("../views/pages/gpdata/EditGpdata"),
  },
  {
    path: "/member-list/edit-member/:id",
    name: "edit-member",
    // meta: {
    //   authRequired: true,
    //   permission: "edit-member",
    // },
    component: () => import("../views/pages/member-list/editMember"),
  },
  {
    path: "/universal-member",
    name: "universal",
    meta: {
      authRequired: true,
      permission: "universal-member",
    },
    component: () => import("../views/pages/universal/index"),
  },
  {
    path: "/universal-member/edit-universal-member/:id",
    name: "edit-universal",
    meta: {
      authRequired: true,
      // permission: "edit-universal-member-list",
    },
    component: () => import("../views/pages/universal/editUniversal"),
  },
  {
    path: "/admin-audit-logs",
    name: "admin-audit-logs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/admin-audit-logs/index"),
  },
  {
    path: "/post",
    name: "post",
    meta: {
      authRequired: true,
      permission: "post-an-update",
    },
    component: () => import("../views/pages/post/index"),
  },
  {
    path: "/post/add-post",
    name: "add-post",
    meta: {
      authRequired: true,
      permission: "add-post-an-update",
    },
    component: () => import("../views/pages/post/addPost"),
  },
  {
    path: "/post/edit-post/:id",
    name: "edit-post",
    meta: {
      authRequired: true,
      permission: "edit-post-an-update",
    },
    component: () => import("../views/pages/post/addPost"),
  },
  {
    path: "/field-rep-tracking",
    name: "field-rep-tracking",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/digiMR/fieldRepTracking"),
  },
  {
    path: "/detailOne",
    name: "detail-one",
    meta: {
      authRequired: false,
      permission: "detail-one",
    },
    component: () => import("../views/pages/detail-one/index"),
  },
  {
    path: "/detailOne/addDetailOne",
    name: "add-detail-one",
    meta: {
      authRequired: false,
      permission: "add-detail1",
    },
    component: () => import("../views/pages/detail-one/addDetailOne"),
  },
  {
    path: "/detailOne/editDetailOne/:id",
    name: "edit-detail-one",
    meta: {
      authRequired: false,
      permission: "edit-detail-one",
    },
    component: () => import("../views/pages/detail-one/addDetailOne"),
  },
  {
    path: "/email-content",
    name: "email-content",
    meta: {
      authRequired: true,
      permission: "email",
    },
    component: () => import("../views/pages/email-content/index"),
  },
  {
    path: "/email-content/add-email-content",
    name: "add-email-content",
    meta: {
      authRequired: true,
      permission: "add-email",
    },
    component: () => import("../views/pages/email-content/addEmailContent"),
  },
  {
    path: "/email-content/edit-email-content/:id",
    name: "edit-email-content",
    meta: {
      authRequired: true,
      permission: "edit-email",
    },
    component: () => import("../views/pages/email-content/addEmailContent"),
  },
  {
    path: "/feedback",
    name: "feedback",
    meta: {
      authRequired: true,
      permission: "feedback",
    },
    component: () => import("../views/pages/feedback/index"),
  },
  {
    path: "/patient-query",
    name: "patient-query",
    meta: {
      authRequired: true,
      permission: "patient-query",
    },
    component: () => import("../views/pages/patient-query/index"),
  },
  {
    path: "/comment",
    name: "comment",
    meta: {
      authRequired: true,
      permission: "comments",
    },
    component: () => import("../views/pages/comment/index"),
  },
  {
    path: "/rating",
    name: "rating",
    meta: {
      authRequired: true,
      permission: "doctor-rating",
    },
    component: () => import("../views/pages/rating/index"),
  },
  {
    path: "/unsubscribe",
    name: "unsubscribe",
    meta: {
      authRequired: true,
      permission: "unsubscribe",
    },
    component: () => import("../views/pages/unsubscribe/index"),
  },
  {
    path: "/points",
    name: "points",
    meta: {
      authRequired: true,
      permission: "points",
    },
    component: () => import("../views/pages/Points/index"),
  },
  {
    path: "/points/add-points",
    name: "add-points",
    meta: {
      authRequired: true,
      permission: "add-points",
    },
    component: () => import("../views/pages/Points/addPoints"),
  },
  {
    path: "/points/redeem-points/:id",
    name: "redeem-points",
    meta: {
      authRequired: true,
      permission: "redeem-points",
    },
    component: () => import("../views/pages/Points/redeemPoints"),
  },
  {
    path: "/points/view-points/:id",
    name: "view-points",
    meta: {
      authRequired: true,
      permission: "view-points",
    },
    component: () => import("../views/pages/Points/viewPoints"),
  },
  {
    path: "/payment",
    name: "payment",
    meta: {
      authRequired: true,
      permission: "payment",
    },
    component: () => import("../views/pages/payment-service/payment/index"),
  },
  {
    path: "/payment-type",
    name: "payment-type",
    meta: {
      authRequired: true,
      permission: "payment-gateway-type",
    },
    component: () =>
      import("../views/pages/payment-service/payment-gatway-type/index"),
  },
  {
    path: "/payment-type/add-payment-type",
    name: "add-payment-type",
    meta: {
      authRequired: true,
      permission: "add-gateway-type",
    },
    component: () =>
      import(
        "../views/pages/payment-service/payment-gatway-type/addPaymentGatewayType"
      ),
  },
  {
    path: "/payment-type/edit-payment-type/:id",
    name: "edit-payment-type",
    meta: {
      authRequired: true,
      permission: "edit-gateway-type",
    },
    component: () =>
      import(
        "../views/pages/payment-service/payment-gatway-type/addPaymentGatewayType"
      ),
  },
  {
    path: "/payment-gatways",
    name: "payment-gatways",
    meta: {
      authRequired: true,
      permission: "payment-gateways",
    },
    component: () =>
      import("../views/pages/payment-service/payment-gateways/index"),
  },
  {
    path: "/payment-gatways/add-payment-gatways",
    name: "add-payment-gatways",
    meta: {
      authRequired: true,
      permission: "add-gateways",
    },
    component: () =>
      import(
        "../views/pages/payment-service/payment-gateways/addPaymentGateways"
      ),
  },
  {
    path: "/payment-gatways/edit-payment-gatways/:id",
    name: "edit-payment-gatways",
    meta: {
      authRequired: true,
      permission: "edit-gateways",
    },
    component: () =>
      import(
        "../views/pages/payment-service/payment-gateways/addPaymentGateways"
      ),
  },
  {
    path: "/order",
    name: "order",
    meta: {
      authRequired: true,
      permission: "order",
    },
    component: () => import("../views/pages/payment-service/order/index"),
  },
  {
    path: "/order/add-manual-payment",
    name: "add-manual-payment",
    meta: {
      authRequired: true,
      permission: "add-manual-payment",
    },
    component: () =>
      import("../views/pages/payment-service/order/addManualPayment"),
  },
  {
    path: "/order/order-item/:id",
    name: "order-item",
    meta: {
      authRequired: true,
      permission: "order-item",
    },
    component: () =>
      import("../views/pages/payment-service/order/order-item/index"),
  },
  {
    path: "/product",
    name: "product",
    meta: {
      authRequired: true,
      permission: "product",
    },
    component: () => import("../views/pages/product"),
  },
  {
    path: "/product/add-product",
    name: "add-product",
    meta: {
      authRequired: true,
      permission: "add-product",
    },
    component: () => import("../views/pages/product/addProduct"),
  },
  {
    path: "/product/edit-product/:id",
    name: "edit-product",
    meta: {
      authRequired: true,
      permission: "edit-product",
    },
    component: () => import("../views/pages/product/addProduct"),
  },
  {
    path: "/payment-logs",
    name: "payment-logs",
    meta: {
      authRequired: true,
      permission: "payment-logs",
    },
    component: () =>
      import("../views/pages/payment-service/payment-logs/index"),
  },

  {
    path: "/wallet",
    name: "wallet",
    meta: {
      authRequired: true,
      permission: "wallet",
    },
    component: () => import("../views/pages/wallet"),
  },
  {
    path: "/wallet/add-wallet",
    name: "add-wallet",
    meta: {
      authRequired: true,
      permission: "wallet",
    },
    component: () => import("../views/pages/wallet/addWalletPoints"),
  },
  {
    path: "/wallet-transaction",
    name: "wallet-transaction",
    meta: {
      authRequired: true,
      permission: "wallet-transaction",
    },
    component: () => import("../views/pages/wallet-transaction"),
  },
  {
    path: "/wallet-transaction/:id",
    name: "single-wallet-transaction",
    meta: {
      authRequired: true,
      permission: "wallet-transaction",
    },
    component: () =>
      import("../views/pages/wallet-transaction/singleWalletTransaction"),
  },
  {
    path: "/plans",
    name: "plans",
    meta: {
      authRequired: true,
      permission: "plan",
    },
    component: () => import("../views/pages/payment-service/Plans/index"),
  },
  {
    path: "/plans/add-plans",
    name: "add-plans",
    meta: {
      authRequired: true,
      permission: "add-plan",
    },
    component: () => import("../views/pages/payment-service/Plans/addPlan"),
  },
  {
    path: "/plans/edit-plans/:id",
    name: "edit-plans",
    meta: {
      authRequired: true,
      permission: "edit-plan",
    },
    component: () => import("../views/pages/payment-service/Plans/addPlan"),
  },
  {
    path: "/coupon",
    name: "coupon",
    meta: {
      authRequired: true,
      permission: "product",
    },
    component: () => import("../views/pages/coupon"),
  },
  {
    path: "/coupon/add-coupon",
    name: "add-coupon",
    meta: {
      authRequired: true,
      permission: "add-coupon",
    },
    component: () => import("../views/pages/coupon/addCoupon"),
  },
  {
    path: "/coupon/edit-coupon/:id",
    name: "edit-coupon",
    meta: {
      authRequired: true,
      permission: "edit-coupon",
    },
    component: () => import("../views/pages/coupon/addCoupon"),
  },
  {
    path: "/tax",
    name: "tax",
    meta: {
      authRequired: true,
      permission: "tax",
    },
    component: () => import("../views/pages/tax"),
  },
  {
    path: "/tax/edit-tax/:id",
    name: "edit-tax",
    meta: {
      authRequired: true,
      permission: "edit-tax",
    },
    component: () => import("../views/pages/tax/addTax"),
  },
  {
    path: "/tax/add-tax",
    name: "add-tax",
    meta: {
      authRequired: true,
      permission: "add-tax",
    },
    component: () => import("../views/pages/tax/addTax"),
  },
  {
    path: "/subscription",
    name: "subscription",
    meta: {
      authRequired: true,
      permission: "subscription",
    },
    component: () => import("../views/pages/payment-service/subscription"),
  },
  {
    path: "/subscription-history",
    name: "subscription-history",
    meta: {
      authRequired: true,
      permission: "subscription-history",
    },
    component: () =>
      import("../views/pages/payment-service/subscription-history"),
  },
  {
    path: "/memebr-faq/:lead_id",
    name: "memebr-faq",
    meta: {
      authRequired: true,
      permission: "member-list",
    },
    component: () => import("../views/pages/member-faq/index"),
  },
  {
    path: "/memebr-faq/add-faq/:lead_id",
    name: "add-faq",
    meta: {
      authRequired: true,
      permission: "member-list",
    },
    component: () => import("../views/pages/member-faq/addFaq"),
  },
  {
    path: "/memebr-faq/edit-faq/:lead_id/:id",
    name: "edit-faq",
    meta: {
      authRequired: true,
      permission: "member-list",
    },
    component: () => import("../views/pages/member-faq/addFaq"),
  },
  {
    path: "/pancard",
    name: "pancard",
    meta: {
      authRequired: true,
      permission: "pancard",
    },
    component: () => import("../views/pages/pancard/index"),
  },
  {
    path: "/wallet-offers",
    name: "wallet-offers",
    meta: {
      authRequired: true,
      permission: "wallet-offers",
    },
    component: () => import("../views/pages/wallet-offers/index"),
  },
  {
    path: "/wallet-offers/add-wallet-offers",
    name: "add-wallet-offers",
    meta: {
      authRequired: true,
      permission: "add-wallet-offers",
    },
    component: () => import("../views/pages/wallet-offers/addWalletOffers"),
  },
  {
    path: "/wallet-offers/edit-wallet-offers/:id",
    name: "edit-wallet-offers",
    meta: {
      authRequired: true,
      permission: "edit-wallet-offers",
    },
    component: () => import("../views/pages/wallet-offers/addWalletOffers"),
  },
  {
    path: "/wallet-Withdraw-request",
    name: "wallet-withdraw",
    meta: {
      authRequired: true,
      permission: "wallet-withdraw-request",
    },
    component: () => import("../views/pages/wallet-withdraw-request/index"),
  },
  {
    path: "/institute-master",
    name: "institute-master",
    meta: {
      authRequired: true,
      permission: "institute-master",
    },
    component: () => import("../views/pages/institite-master/index"),
  },
  {
    path: "/institute-master/edit-institute/:id",
    name: "edit-institute-master",
    meta: {
      authRequired: true,
      permission: "institute-master",
    },
    component: () => import("../views/pages/institite-master/EditInstitute"),
  },
  {
    path: "/gift-company",
    name: "gift-company",
    meta: {
      authRequired: true,
      permission: "gift-company",
    },
    component: () =>
      import("../views/pages/payment-service/gift-company/index"),
  },
  {
    path: "/gift-company/add-gift-company",
    name: "add-gift-company",
    meta: {
      authRequired: true,
      permission: "add-gift-company",
    },
    component: () =>
      import("../views/pages/payment-service/gift-company/addGiftCompany"),
  },
  {
    path: "/gift-company/edit-gift-company/:id",
    name: "edit-gift-company",
    meta: {
      authRequired: true,
      permission: "edit-gift-company",
    },
    component: () =>
      import("../views/pages/payment-service/gift-company/addGiftCompany"),
  },
  {
    path: "/gift-card",
    name: "gift-card",
    meta: {
      authRequired: true,
      permission: "gift-card",
    },
    component: () => import("../views/pages/payment-service/gift-card/index"),
  },
  {
    path: "/gift-card/add-gift-card",
    name: "add-gift-card",
    meta: {
      authRequired: true,
      permission: "add-gift-card",
    },
    component: () =>
      import("../views/pages/payment-service/gift-card/addGiftCard"),
  },
  {
    path: "/gift-card/edit-gift-card/:id",
    name: "edit-gift-card",
    meta: {
      authRequired: true,
      permission: "edit-gift-card",
    },
    component: () =>
      import("../views/pages/payment-service/gift-card/addGiftCard"),
  },
  {
    path: "/gift-card-history",
    name: "gift-card-history",
    meta: {
      authRequired: true,
      permission: "gift-card-history",
    },
    component: () =>
      import("../views/pages/payment-service/gift-card-history/index"),
  },
  {
    path: "/url-shortner",
    name: "url-shortner",
    meta: {
      authRequired: true,
      permission: "url-shortner",
    },
    component: () => import("../views/pages/url-shortner/index"),
  },
  {
    path: "/survey",
    name: "survey",
    meta: {
      authRequired: true,
      permission: "survey",
    },
    component: () => import("../views/pages/survey/index"),
  },
  {
    path: "/survey/add-survey",
    name: "add-survey",
    meta: {
      authRequired: true,
      permission: "add-survey",
    },
    component: () => import("../views/pages/survey/addSurvey"),
  },
  {
    path: "/survey/edit-survey/:id",
    name: "edit-survey",
    meta: {
      authRequired: true,
      permission: "edit-survey",
    },
    component: () => import("../views/pages/survey/addSurvey"),
  },
  {
    path: "/qr-code",
    name: "Qr-Code",
    meta: {
      authRequired: true,
      permission: "url-shortner",
    },
    component: () => import("../views/pages/qr-code/index"),
  },
  {
    path: "/survey-question-list/:id",
    name: "survey-question-list",
    meta: {
      authRequired: true,
      permission: "survey",
    },
    component: () => import("../views/pages/survey/surveyQuestionList"),
  },
  {
    path: "/survey-question/add-survey-question",
    name: "add-survey-question",
    meta: {
      authRequired: true,
      permission: "add-survey",
    },
    component: () => import("../views/pages/survey/addSurveyQuestion"),
  },
  {
    path: "/survey-question/edit-survey-question/:id",
    name: "edit-survey-question",
    meta: {
      authRequired: true,
      permission: "edit-survey",
    },
    component: () => import("../views/pages/survey/addSurveyQuestion"),
  },
  {
    path: "/report-survey/:id",
    name: "report-survey",
    meta: {
      authRequired: true,
      permission: "survey",
    },
    component: () => import("../views/pages/survey/reportSurvey"),
  },
  {
    path: "/clinic",
    name: "clinic",
    meta: {
      authRequired: true,
      permission: "clinic",
    },
    component: () => import("../views/pages/hospital/clinic/index"),
  },
  {
    path: "/clinic/add-clinic",
    name: "add-clinic",
    meta: {
      authRequired: true,
      permission: "add-clinic",
    },
    component: () => import("../views/pages/hospital/clinic/addClinic"),
  },
  {
    path: "/clinic/edit-clinic/:id",
    name: "edit-clinic",
    meta: {
      authRequired: true,
      permission: "edit-clinic",
    },
    component: () => import("../views/pages/hospital/clinic/addClinic"),
  },
  {
    path: "/hospital-timings/add-hospital-timings/:id",
    name: "add-hospital-timings",
    meta: {
      authRequired: true,
      permission: "add-clinic-timings",
    },
    component: () =>
      import("../views/pages/hospital/hospital-timings/addHospitalTimings"),
  },
  {
    path: "/hospital-timings/edit-hospital-timings/:id",
    name: "edit-hospital-timings",
    meta: {
      authRequired: true,
      permission: "edit-clinic-timings",
    },
    component: () =>
      import("../views/pages/hospital/hospital-timings/addHospitalTimings"),
  },
  {
    path: "/services/add-services/:id",
    name: "add-services",
    meta: {
      authRequired: true,
      permission: "add-clinic-services",
    },
    component: () => import("../views/pages/hospital/services/addServices"),
  },
  {
    path: "/amenity/add-amenity/:id",
    name: "add-amenity",
    meta: {
      authRequired: true,
      permission: "add-clinic-amenity",
    },
    component: () => import("../views/pages/hospital/amenity/addAmenity"),
  },
  {
    path: "/faqs/add-faqs/:id",
    name: "add-faqs",
    meta: {
      authRequired: true,
      permission: "add-clinic-faqs",
    },
    component: () => import("../views/pages/hospital/faq/addFaqs"),
  },
  {
    path: "/faqs/edit-faqs/:id",
    name: "edit-faqs",
    meta: {
      authRequired: true,
      permission: "edit-clinic-faqs",
    },
    component: () => import("../views/pages/hospital/faq/addFaqs"),
  },
  {
    path: "/social-links/edit-social-links/:id",
    name: "edit-social-links",
    meta: {
      authRequired: true,
      permission: "clinic-social-link",
    },
    component: () =>
      import("../views/pages/hospital/social-links/addSocialLinks"),
  },
  {
    path: "/gallery/add-clinic-gallery/:id",
    name: "add-clinic-gallery",
    meta: {
      authRequired: true,
      permission: "add-clinic-gallery",
    },
    component: () =>
      import("../views/pages/hospital/clinic-gallery/addClinicGallery"),
  },
  {
    path: "/gallery/edit-clinic-gallery/:id",
    name: "edit-clinic-gallery",
    meta: {
      authRequired: true,
      permission: "edit-clinic-gallery",
    },
    component: () =>
      import("../views/pages/hospital/clinic-gallery/editClinicGallery"),
  },
  {
    path: "/member-tagging/add-member-tagging/:id",
    name: "add-member-tagging",
    meta: {
      authRequired: true,
      permission: "add-clinic-members",
    },
    component: () =>
      import("../views/pages/hospital/member-tagging/addMemberTagging"),
  },
  {
    path: "/patient-stories/add-patient-stories/:id",
    name: "add-patient-stories",
    meta: {
      authRequired: true,
      permission: "add-clinic-patient-stories",
    },
    component: () =>
      import("../views/pages/hospital/patient-stories/addPatientStories"),
  },
  {
    path: "/patient-stories/edit-patient-stories/:id",
    name: "edit-patient-stories",
    meta: {
      authRequired: true,
      permission: "edit-clinic-patient-stories",
    },
    component: () =>
      import("../views/pages/hospital/patient-stories/editPatientStories"),
  },
  {
    path: "/field-rep-app/campaigns",
    name: "campaigns",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/campaign/index"),
  },
  {
    path: "/field-rep-app/add-campaign",
    name: "add-campaigns",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/field-rep-app/campaign/addCampaign"),
  },
  {
    path: "/field-rep-app/edit-campaign/:id",
    name: "edit-campaigns",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/field-rep-app/campaign/addCampaign"),
  },

  {
    path: "/field-rep-app/card",
    name: "card",
    meta: {
      // authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/card/index"),
  },
  {
    path: "/field-rep-app/add-card",
    name: "add-card",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/card/addCard"),
  },
  {
    path: "/field-rep-app/edit-card/:id",
    name: "edit-card",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/card/addCard"),
  },
  {
    path: "/journal",
    name: "journal",
    meta: {
      authRequired: true,
      permission: "journal-list",
    },
    component: () => import("../views/pages/journal/index"),
  },
  {
    path: "/osm-gift-card",
    name: "osm-gift-card",
    meta: {
      authRequired: true,
      permission: "gift-card",
    },
    component: () => import("../views/pages/gift-card/index"),
  },
  {
    path: "/field-rep-app/campaign-contents",
    name: "campaign-content",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/content/index"),
  },
  {
    path: "/field-rep-app/add-contents",
    name: "add-campaign-content",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/content/addContent"),
  },
  {
    path: "/field-rep-app/edit-contents/:id",
    name: "edit-campaign-content",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/content/addContent"),
  },
  {
    path: "/survey-payouts",
    name: "survey-payouts",
    meta: {
      authRequired: true,
      permission: "survey",
    },
    component: () => import("../views/pages/surveyPayouts/index"),
  },

  {
    path: "/patient-library/templates",
    name: "template",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patientLibrary/template/index"),
  },
  {
    path: "/patient-library/add-template",
    name: "add-template",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/patientLibrary/template/addtemplate"),
  },
  {
    path: "/patient-library/edit-template/:id",
    name: "edit-template",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/patientLibrary/template/addtemplate"),
  },

  {
    path: "/patient-library/categories",
    name: "category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patientLibrary/category/index"),
  },
  {
    path: "/patient-library/add-category",
    name: "add-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/patientLibrary/category/addcategory"),
  },
  {
    path: "/patient-library/edit-category/:id",
    name: "edit-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/patientLibrary/category/addcategory"),
  },

  {
    path: "/patient-library/sub-categories",
    name: "subcategory",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patientLibrary/subcategory/index"),
  },
  {
    path: "/patient-library/add-subcategory",
    name: "add-subcategory",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/patientLibrary/subcategory/addsubcategory"),
  },
  {
    path: "/patient-library/edit-subcategory/:id",
    name: "edit-subcategory",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/patientLibrary/subcategory/addsubcategory"),
  },

  {
    path: "/patient-library/languages",
    name: "language",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patientLibrary/language/index"),
  },
  {
    path: "/patient-library/add-language",
    name: "add-language",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/patientLibrary/language/addlanguage"),
  },
  {
    path: "/patient-library/edit-language/:id",
    name: "edit-language",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/patientLibrary/language/addlanguage"),
  },
  {
    path: "/field-rep-app/campaign-speciality-data",
    name: "campaign-speciality-data",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/specialityData/index"),
  },
  {
    path: "/field-rep-app/add-campaign-speciality-data",
    name: "add-campaign-speciality-data",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/specialityData/addSpecialityData"),
  },
  {
    path: "/field-rep-app/edit-campaign-speciality-data/:id",
    name: "edit-campaign-speciality-data",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/specialityData/addSpecialityData"),
  },
  {
    path: "/field-rep-app/campaign-brand-speciality-data",
    name: "campaign-brand-speciality-data",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/brandSpecialityData/index"),
  },
  {
    path: "/field-rep-app/add-campaign-brand-speciality-data",
    name: "add-campaign-brand-speciality-data",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/brandSpecialityData/addBrandSpecialityData"),
  },
  {
    path: "/field-rep-app/edit-campaign-brand-speciality-data/:id",
    name: "edit-campaign-brand-speciality-data",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/field-rep-app/brandSpecialityData/addBrandSpecialityData"),
  },
  {
    path: "/test-admin",
    name: "test-admin",
    meta: {
      authRequired: true,
      permission: "dashboard",
    },
    component: () => import("../views/pages/test-admin/index"),
  },
  {
    path: "/medi-blog/posts",
    name: "posts",
    meta: {
      authRequired: true,
      // permission: "post-an-update",
    },
    component: () => import("../views/pages/MediBlog/posts/index"),
  },
  {
    path: "/medi-blog/add-posts",
    name: "add-posts",
    meta: {
      authRequired: true,
      // permission: "add-post-an-update",
    },
    component: () => import("../views/pages/MediBlog/posts/addPosts"),
  },
  {
    path: "/medi-blog/edit-posts/:id",
    name: "edit-posts",
    meta: {
      authRequired: true,
      // permission: "edit-post-an-update",
    },
    component: () => import("../views/pages/MediBlog/posts/addPosts"),
  },
  {
    path: "/medi-blog/categories",
    name: "categories",
    meta: {
      authRequired: true,
      // permission: "post-an-update",
    },
    component: () => import("../views/pages/MediBlog/categories/index"),
  },
  {
    path: "/medi-blog/add-categories",
    name: "add-categories",
    meta: {
      authRequired: true,
      // permission: "add-post-an-update",
    },
    component: () => import("../views/pages/MediBlog/categories/addCategories"),
  },
  {
    path: "/medi-blog/edit-categories/:id",
    name: "edit-categories",
    meta: {
      authRequired: true,
      // permission: "edit-post-an-update",
    },
    component: () => import("../views/pages/MediBlog/categories/addCategories"),
  },
];
